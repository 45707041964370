import { css } from '@emotion/react';
import mq from './mq';

const marks = css`
  body {
    /* border: 10px solid red;
    ${mq.mqMin(`sm`)} {
      border-color: pink;
    }
    ${mq.mqMin(`md`)} {
      border-color: orange;
    }
    ${mq.mqMin(`lg`)} {
      border-color: green;
    }
    ${mq.mqMin(`xl`)} {
      border-color: blue;
    } */
  }
`;

export default marks;
