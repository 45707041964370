/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

interface Breakpoints {
  [key: string]: number;
}

const breakpoints: Breakpoints = {
  xxsm: 350,
  xsm: 450,
  sm: 575,
  sm600: 600,
  md: 766.98,
  lg: 991,
  xl: 1200,
  xxl: 1400,
  xxxl: 1800
};

const mqMax = (
  value: `xxsm` | `xsm` | `sm` | `md` | `lg` | `xl` | `sm600` | `xxl` | `xxxl`
) => {
  const bpArray = Object.keys(breakpoints).map((key) => [
    key,
    breakpoints[key],
  ]);

  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (value === name) return [...acc, `@media (max-width: ${size}px)`];
    return acc;
  }, []);

  return result;
};

const mqMin = (
  value: `xxsm` | `xsm` | `sm` | `md` | `lg` | `xl` | `sm600` | `xxl` | `xxxl`
) => {
  const bpArray = Object.keys(breakpoints).map((key) => [
    key,
    breakpoints[key],
  ]);

  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (value === name) return [...acc, `@media (min-width: ${size}px)`];
    return acc;
  }, []);

  return result;
};

const mqMinMax = (
  min: `xxsm` | `xsm` | `sm` | `md` | `lg` | `xl` | `sm600` | `xxl` | `xxxl`,
  max: `xxsm` | `xsm` | `sm` | `md` | `lg` | `xl` | `sm600` | `xxl` | `xxxl`
) => {
  const bpArray = Object.keys(breakpoints).map((key) => [
    key,
    breakpoints[key],
  ]);

  const [resultMin] = bpArray.reduce((acc, [name, size]) => {
    if (min === name) return [...acc, `@media (min-width: ${size}px)`];
    return acc;
  }, []);

  const [resultMax] = bpArray.reduce((acc, [name, size]) => {
    if (max === name) return [...acc, ` and (max-width: ${size}px)`];
    return acc;
  }, []);

  return ((resultMin as string) + resultMax) as string;
};

export default { mqMax, mqMin, mqMinMax };
