import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    colorPrimary: '#0e3463',
    colorSecondary: '#23B150',
    colorThird: '#02162f',
    colorGreen: '#006400',
    colorAccent: '#3C3C3B',
    colorSuccess: '#88C459',
    colorError: '#F5414F',
    colorWarning: '#FFD137',
    white: '#FFFFFF',
    gra100: ' #F8F9FA',
    gray200: '#E9ECEF',
    gray300: '#DEE2E6',
    gray400: '#CED4DA',
    gray500: '#ADB5BD',
    gray600: '#6C757D',
    gray700: '#495057',
    gray800: '#343A40',
    gray900: '#212529',
    black: '#000000',
  },
  styles: {
    global: {
      // styles for the `body`
      main: {
        //backgroundColor: '#E9ECEF',
        boxSizing: 'border-box',
      },
      body: {
        lineHeight: '1.5',
        fontFamily: 'Quicksand, sans-serif',
        //backgroundColor: '#E9ECEF',
      },
    },
  },
});

export default theme;
